<!--
 * @Description: AKJERRT
 * @Date: 2022-09-15 16:02:23
 * @LastEditTime: 2022-09-15 16:15:31
 * @FilePath: \linkgap-front\src\views\aarget_budget\components\card.vue
-->
<template>
  <div>
    <div v-for="item in data" :key="item.key">
      <a-card title="第一季度">
        <a slot="extra" href="#">{{ yearTarget }}￥</a>
        <a-card-grid style="text-align: center">
          1月
          <a-input prefix="￥" suffix="RMB" />
        </a-card-grid>
        <a-card-grid style="text-align: center">
          2月
          <a-input prefix="￥" suffix="RMB" />
        </a-card-grid>
        <a-card-grid style="text-align: center">
          3月
          <a-input prefix="￥" suffix="RMB" />
        </a-card-grid>
      </a-card>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: [Object, Array],
      default: () => ({}),
    },
  },
  data() {
    return {}
  },
  mounted() {
    console.log(JSON.parse(JSON.stringify(this.data)), 'card组件')
  },
  methods: {},
}
</script>

<style lang="scss" scoped>
</style>
